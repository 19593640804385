<i *ngIf="!this.salesDataService.Data" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
<div class="navBody">
  <app-navbar></app-navbar>
  <div class="control-row">
    <p-dropdown [options]="this.categories" [(ngModel)]="Category"
                placeholder="Filter By Category"
                filter="true"
                filterPlaceholder="Type Here to Filter Results"
                (ngModelChange)="FilterUsingCategoryDropdown(Category)">
      <ng-template let-group pTemplate="group">
        <div class="p-d-flex p-ai-center">
          <i [class]="'pi pi-chevron-right p-mr-2 flag flag-' + group.value"></i>
          <span>{{group.label}}</span>
        </div>
      </ng-template>
    </p-dropdown>
    <p-dropdown [options]="itemGroups" [(ngModel)]="Item"
                placeholder="Filter By Item"
                [group]="true"
                filter="true"
                filterPlaceholder="Type Here to Filter Results"
                (ngModelChange)="FilterUsingItemDropdown(Item)">
      <ng-template let-group pTemplate="group">
        <div class="p-d-flex p-ai-center">
          <i [class]="'pi pi-chevron-right p-mr-2 flag flag-' + group.value"></i>
          <span>{{group.label}}</span>
        </div>
      </ng-template>
    </p-dropdown>
    <p-button (click)="Reset()">Clear Filters</p-button>
    <p-button *ngIf="currentlyFocusingChart" (click)="UnFocusChart(this.charts[chartIndex])">
       Back<i class="pi pi-window-minimize"></i> </p-button>
  </div>
</div>
<div [ngClass]="this.arrangement">

  <app-ytd [hidden]="this.chartIndex != -1 && this.chartIndex != 0"
           #ytd
           id="sales-chart-zero"
           class="chartWrapper"
           (click)="Arrange(0)"></app-ytd>

  <app-glance-two-month [hidden]="this.chartIndex != -1 && this.chartIndex != 1"
                        #twoMonth
                        id="sales-chart-one"
                        class="chartWrapper"
                        (click)="Arrange(1)"></app-glance-two-month>

  <app-glance-four-week [hidden]="this.chartIndex != -1 && this.chartIndex != 2"
                        #fourWeek
                        id="sales-chart-two"
                        class="chartWrapper"
                        (click)="Arrange(2)"></app-glance-four-week>

  <app-fourteen-day [hidden]="this.chartIndex != -1 && this.chartIndex != 3"
                    #fourteenDay
                    id="sales-chart-three"
                    class="chartWrapper"
                    (click)="Arrange(3)"></app-fourteen-day>

  <app-zip-code [hidden]="this.chartIndex != -1 && this.chartIndex != 4"
                #zipCode
                id="sales-chart-four"
                class="chartWrapper"
                (click)="Arrange(4)"></app-zip-code>

  <app-top-category [hidden]="(this.chartIndex != -1 && this.chartIndex != 5)"
                    #topCategory
                    id="sales-chart-five"
                    class="chartWrapper"
                    (click)="Arrange(5)"></app-top-category>

  <app-top-item [hidden]="(this.chartIndex != -1 && this.chartIndex != 6) || this.Item"
                #topItem
                id="sales-chart-six"
                class="chartWrapper"
                (click)="Arrange(6)"></app-top-item>

  <app-item-quantity [hidden]="(this.chartIndex != -1 && this.chartIndex != 7) || this.Item"
                     #topQuantity
                     id="sales-chart-seven"
                     class="chartWrapper"
                     (click)="Arrange(7)"></app-item-quantity>
</div>
<br>

