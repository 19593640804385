import {Component, OnInit} from '@angular/core';
import {DateService} from '../../../../services/date.service';
import {PlantedChart} from '../../../../planted-charts/planted-chart';
import {ChartConfig} from '../../../../planted-charts/DefinedTypes/chart-config';
import {ChartType} from 'angular-google-charts';
import {ChartOptions} from '../../../../planted-charts/DefinedTypes/chart-options';
import {TS} from 'typescript-linq';
import Enumerator = TS.Linq.Enumerator;
import {ChartService} from '../../../../services/chart.service';
import {SalesDataService} from '../../../../services/sales-data.service';

@Component({
  selector: 'app-zip-code',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss']
})
export class ZipCodeComponent extends PlantedChart implements OnInit {

  constructor(protected dateService: DateService,
              protected chartService: ChartService,
              protected salesDataService: SalesDataService) {
    super(dateService, chartService, salesDataService);
  }

  public MaxTitle = 'Sales By Zip Code '
    + this.salesDataService.Data.twoMonthly[1].month + ' to ' + this.salesDataService.Data.twoMonthly[1].month;
  public MinTitle = 'Sales by Zip Code ';

  protected focus: string;

  type = ChartType.BarChart;

  ngOnInit(): void {
  }

  Render(): void {


    this.config = this.ChartSetup();
    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();

    this.chartService.ReDraw(this);
  }


  // TODO Not this return type.
  public ChartSetup(): ChartConfig {
    let cfg = this.constructConfig();

    cfg.Title = this.MinTitle;
    cfg.Options = new ChartOptions();
    cfg.Options.hAxis.title = 'Sales (USD)';
    cfg.Options.hAxis.format = '$#,###';
    cfg.Options.vAxis.title = 'Zip';
    cfg.Columns = ['Zip', 'Sales', {role: 'tooltip'}];
    cfg.Options.legend = 'none';

    let sd = this.salesDataService.Data.thirtyDaysRollingZip;

    this.FullColumnHeaders = new Enumerator(sd).select(item => item.label).toArray();

    cfg.Data = [];

    // [sd[0].label, sd[0].total, `${sd[0].label}:$${sd[0].total}`],
    //   [sd[1].label, sd[1].total, `${sd[1].label}:$${sd[1].total}`],
    //   [sd[2].label, sd[2].total, `${sd[2].label}:$${sd[2].total}`],
    //   [sd[3].label, sd[3].total, `${sd[3].label}:$${sd[3].total}`],
    //   [sd[4].label, sd[4].total, `${sd[4].label}:$${sd[4].total}`],
    //   [sd[5].label, sd[5].total, `${sd[5].label}:$${sd[5].total}`],

    for (let i = 0; i < sd.length; i++) {
      cfg.Data.push([sd[i].label, sd[i].total, `${sd[i].label}:$${sd[i].total}`]);
    }

    return cfg;
  }
}
