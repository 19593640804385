import {SelectItem} from 'primeng';

export class PDropdownItem implements SelectItem {
  label?: string;
  value: any;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;

  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }
}
