import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LandingComponent} from './components/landing/landing.component';
import {SalesComponent} from './components/dashboard/sales/sales.component';
import {SalesDataService} from './services/sales-data.service';


const appRoutes: Routes = [
  {
    path: '', component: LandingComponent, resolve: {SalesDataService}
  },
  {
    path: 'sales', component: SalesComponent, resolve: {SalesDataService}
  },
  // {
  //   path: 'growers', component: GrowersComponent,
  // },
  {
    path: '**', redirectTo: '', pathMatch: 'full', resolve: SalesDataService
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
