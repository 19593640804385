import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {ModelFilter} from '../../../LibraryTypes/model-filter';
import {PlantedChart} from '../../../planted-charts/planted-chart';
import {SelectItem, SelectItemGroup} from 'primeng';
import {ChartService} from '../../../services/chart.service';
import {AppComponent} from '../../../app.component';
import {SalesDataService} from '../../../services/sales-data.service';
import {TS} from 'typescript-linq';
import Enumerator = TS.Linq.Enumerator;
import {PDropdownCategory} from '../../../models/pdropdown-category';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class SalesComponent implements OnInit, AfterViewInit {

  navcolor: ThemePalette = 'accent';
  chartIndex = -1;
  charts: PlantedChart[] = [];
  @ViewChild('ytd') ytd;
  @ViewChild('twoMonth') twoMonth;
  @ViewChild('fourWeek') fourWeek;
  @ViewChild('fourteenDay') fourteenDay;
  @ViewChild('zipCode') zipCode;
  @ViewChild('topCategory') topCategory;
  @ViewChild('topItem') topItem;
  @ViewChild('topQuantity') topQuantity;

  startDate: number;

  categories: SelectItem[];

  itemGroups: SelectItemGroup[];

  items: SelectItem[];
  arrangement = 'chart-container';
  scope = 'Revenue';
  currentlyFocusingChart: boolean;

  Item: string;
  Category: string;

  constructor(
    public chartService: ChartService,
    private appComponent: AppComponent,
    public salesDataService: SalesDataService
  ) {
    this.currentlyFocusingChart = false;

    // Populate Dropdowns from SalesDashData
    this.itemGroups = new Enumerator(this.salesDataService.Data.categories)
      .select(x => new PDropdownCategory(x, this.salesDataService.Data.variants)).toArray();
  }

  async ngOnInit(): Promise<void> {
    this.Item = this.salesDataService.Item;
    this.Category = this.salesDataService.Category;
    this.categories = new TS.Linq.Enumerator(this.salesDataService.Data.categories)
      .select(x => {
        return {label: x, value: x} as SelectItem;
      }).toArray();
  }

  ngAfterViewInit(): void {
    // This must fall perfectly in order with the order of the html in the
    // corresponding template in order to function properly.
    this.charts.push(
      this.ytd,
      this.twoMonth,
      this.fourWeek,
      this.fourteenDay,
      this.zipCode,
      this.topCategory,
      this.topItem,
      this.topQuantity
    );

    this.chartService.Charts = this.charts;

    this.RenderCharts();
    this.UpdateTitles('Revenue');
    this.appComponent.resize$.next();
  }


  private UpdateTitles(scope: string, filterClause: string = null): void {

    let zipAppender = '';
    let appender = '';

    switch (scope) {
      case 'Revenue':
        break;
      case 'Category':
        zipAppender = ' (All Categories)';
        appender = ' (' + filterClause + ')';
        break;
      case 'Item':
        zipAppender = ' (All Items)';
        appender = ' (' + filterClause + ')';
        break;
    }
  }

  Arrange(index: number): void {
    let theChart = this.charts[index];
    if (index === this.chartIndex) return;
    else this.FocusChart(theChart, index);
  }

  UnFocusChart(chart: PlantedChart): void {
    this.arrangement = 'chart-container';
    this.currentlyFocusingChart = false;
    chart.isInFocus = false;
    chart.config.Height = this.chartService.ChartMinH;
    chart.config.Width = this.chartService.ChartMinW;
    chart.config.Title = chart.MinTitle;
    chart.config = chart.DisplayShortColumnHeaders();
    this.chartIndex = -1;
  }

  private FocusChart(chart: PlantedChart, index: number): void {
    this.chartIndex = index;
    this.arrangement = 'chart-container' + index;
    this.currentlyFocusingChart = true;
    chart.isInFocus = true;
    chart.config.Height = this.chartService.ChartMaxH;
    chart.config.Width = this.chartService.ChartMaxW;
    chart.config.Title = chart.MaxTitle;
    chart.config = chart.DisplayFullColumnHeaders();
  }

  FilterUsingCategoryDropdown(value: string): void {
    this.Category = value;
    this.Item = null;
    this.salesDataService.Reload(value, null).then(x => this.RenderCharts());
  }

  FilterUsingItemDropdown(value: string): void {
    this.Item = value;
    this.Category = null;
    this.salesDataService.Reload(null, value).then(x => this.RenderCharts());
    this.RenderCharts();
  }

  Reset(): void {
    this.Item = null;
    this.Category = null;
    this.salesDataService.Reload(null, null).then(x => this.RenderCharts());
  }

  RenderCharts(): void {
    for (let i = 0; i < this.charts.length; i++) {
      try {
        let c = this.charts[i];
        c.Render();
      } catch (e) {
        console.log(e);
      }
    }
  }
}
