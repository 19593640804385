import {Injectable} from '@angular/core';
import {
  addDays,
  addMonths,
  addWeeks,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';
import {TS} from 'typescript-linq';
import {TimeSpan} from '../time-span.enum';
import {ModelFilter} from '../LibraryTypes/model-filter';
import Exception = TS.Exception;
import Enumerator = TS.Linq.Enumerator;
import {TimeSlot} from '../models/time-slot';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  // user friendly or otherwise easy to read.
  ConvertToDisplayDate(num: number): string {
    return new Date(num).toISOString().split('T')[0];
  }

  // for example a date converted to UTC time, falls between 2 other numbers.
  IsBetween(start: number, value: number, end: number): boolean {
    let outcome: boolean = end >= value && value >= start;
    return outcome;
  }


  ConvertToDayLabel(date: Date): string {
    if (isMonday(date)) return 'Mon';
    else if (isTuesday(date)) return 'Tue';
    else if (isWednesday(date)) return 'Wed';
    else if (isThursday(date)) return 'Thu';
    else if (isFriday(date)) return 'Fri';
    else if (isSaturday(date)) return 'Sat';
    else if (isSunday(date)) return 'Sun';
    else return 'UhOh';
  }
}
