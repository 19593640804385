import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ChartConfig} from '../../../../planted-charts/DefinedTypes/chart-config';
import {TS} from 'typescript-linq';
import Enumerator = TS.Linq.Enumerator;
import {ModelFilter} from '../../../../LibraryTypes/model-filter';
import {PlantedChart} from '../../../../planted-charts/planted-chart';
import {DateService} from '../../../../services/date.service';
import {TimeSpan} from '../../../../time-span.enum';
import {ChartType} from 'angular-google-charts';
import {ChartOptions} from '../../../../planted-charts/DefinedTypes/chart-options';
import {ChartService} from '../../../../services/chart.service';
import {SalesDataService} from '../../../../services/sales-data.service';

@Component({
  selector: 'app-ytd',
  templateUrl: 'chart.html',
  styleUrls: ['./chart.scss']
})
export class YtdComponent extends PlantedChart implements OnInit {

  _title: string;

  // @ts-ignore
  get MaxTitle(): string {
    return this._title;
  }

  // @ts-ignore
  get MinTitle(): string {
    return this._title;
  }

  ShortColumnHeaders: string[] = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
  FullColumnHeaders: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  private trailing = 3;

  type = ChartType.ComboChart;

  protected focus: string;

  constructor(protected dateService: DateService,
              protected chartService: ChartService,
              protected salesDataService: SalesDataService) {
    super(dateService, chartService, salesDataService);
  }

  async ngOnInit(): Promise<void> {
  }

  public Render(): void {
    let appendedValue = this.salesDataService.Category ?? this.salesDataService.Item ?? null;

    if (appendedValue) {
      this._title = `Sales, Year To Date: ${new Date().getFullYear()} for ${appendedValue}`;
    } else {
      this._title = `Sales, Year To Date: ${new Date().getFullYear()}`;
    }

    this.config = this.ChartSetup();
    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();
    this.chartService.ReDraw(this);

  }

  public ChartSetup(): ChartConfig {
    let cfg = this.constructConfig();
    let sd = new TS.Linq.Enumerator(this.salesDataService.Data.ytd).reverse().toArray();
    cfg.Title = this.MinTitle;
    cfg.Options = new ChartOptions();
    cfg.Options.vAxis.title = 'Sales (USD)';
    cfg.Options.vAxis.format = '$#,###';
    cfg.Options.legend = 'none';
    cfg.Columns = ['Month', 'Total', {role: 'tooltip'}, 'Year To Date', {role: 'tooltip'}, 'Quarter To Date', {role: 'tooltip'}];
    cfg.Options.seriesType = 'bars';
    cfg.Options.series = {1: {type: 'line'}, 2: {type: 'line'}};

    let rollingValues: number[] = [];
    let trailer: number[] = [];

    for (let b = 0; b < sd.length; b++) {
      if (sd[b].total === 0 || sd[b] === null) {
        rollingValues[b] = null;
      } else {
        if (b === 0) {
          rollingValues[b] = sd[b].total;
        } else {
          rollingValues[b] = sd[b].total + rollingValues[b - 1];
        }
      }

      if (sd[b].total === 0 || sd[b] === null) {
        trailer[b] = null;
      } else {
        trailer = [];
        let roller = 0;

        for (let a = this.trailing - 1; a >= 0; a--) {
          if ((b - a) >= 0) {
            trailer[b - a] = roller + sd[b - a].total;
            roller += sd[b - a].total;
          }
        }
      }
    }

    let cH: string[] = this.ShortColumnHeaders;
    let cHL: string[] = this.FullColumnHeaders;

    let revToolTip: string[] = [];
    let rollToolTip: string[] = [];
    let trailToolTip: string[] = [];
    for (let c = 0; c < cH.length; c++) {
      revToolTip.push(`${cHL[c]}: $${sd[c]?.total ?? 0}`);
      rollToolTip.push(`YTD at end of ${cHL[c]}: $${rollingValues[c]}`);
      trailToolTip.push(`Trailing 3 months at end of ${cHL[c]}: $${trailer[c]}`);
    }

    cfg.Data =
      [
        [cH[0], sd[0]?.total ?? 0, revToolTip[0], rollingValues[0], rollToolTip[0], trailer[0], trailToolTip[0]],
        [cH[1], sd[1]?.total ?? 0, revToolTip[1], rollingValues[1], rollToolTip[1], trailer[1], trailToolTip[1]],
        [cH[2], sd[2]?.total ?? 0, revToolTip[2], rollingValues[2], rollToolTip[2], trailer[2], trailToolTip[2]],
        [cH[3], sd[3]?.total ?? 0, revToolTip[3], rollingValues[3], rollToolTip[3], trailer[3], trailToolTip[3]],
        [cH[4], sd[4]?.total ?? 0, revToolTip[4], rollingValues[4], rollToolTip[4], trailer[4], trailToolTip[4]],
        [cH[5], sd[5]?.total ?? 0, revToolTip[5], rollingValues[5], rollToolTip[5], trailer[5], trailToolTip[5]],
        [cH[6], sd[6]?.total ?? 0, revToolTip[6], rollingValues[6], rollToolTip[6], trailer[6], trailToolTip[6]],
        [cH[7], sd[7]?.total ?? 0, revToolTip[7], rollingValues[7], rollToolTip[7], trailer[7], trailToolTip[7]],
        [cH[8], sd[8]?.total ?? 0, revToolTip[8], rollingValues[8], rollToolTip[8], trailer[8], trailToolTip[8]],
        [cH[9], sd[9]?.total ?? 0, revToolTip[9], rollingValues[9], rollToolTip[9], trailer[9], trailToolTip[9]],
        [cH[10], sd[10]?.total ?? 0, revToolTip[10], rollingValues[10], rollToolTip[10], trailer[10], trailToolTip[10]],
        [cH[11], sd[11]?.total ?? 0, revToolTip[11], rollingValues[11], rollToolTip[11], trailer[11], trailToolTip[11]]
      ];

    let checker = new Enumerator(sd).select(x => x.total);
    if (checker.defaultIfEmpty(0).sum() !== 0)
      cfg.Options.vAxis.maxValue = (checker.where(t => t != null).max() * 1.25);
    // Assigning this value back to the property/class level will cause the chart to draw on screen per the *ngIf
    // directive present on the template.
    return cfg;
  }
}
