<div class="centerThis">
  <i *ngIf="!this.config" class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  <google-chart *ngIf="this.config != null"
                [title]="this.config.Title + this.titleSuffix"
                [type]="this.config.Type"
                [data]="this.config.Data"
                [columns]="this.config.Columns"
                [options]="this.config.Options"
                [width]="this.config.Width"
                [height]="this.config.Height"
                dynamicResize="true">
  </google-chart>
</div>
