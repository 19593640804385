import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {User} from '../models/user';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {RedirectService} from './redirect.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private http: HttpClient;

  constructor(private redirectService: RedirectService, handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  async Get(): Promise<string> {
    try {
      console.log('Seeing if token is present');
      let token = localStorage.getItem(environment.key);
      console.log('Seeing if holdover is present');
      let holdOverId = new URLSearchParams(window.location.search).get('holdOver');
      if (token) {
        console.log('Found Token in Local Storage');
        console.log({token});
        return token;
      } else if (holdOverId) {
        console.log('Found Holdover Id.');

        await this.http.post<any>(environment.demeterApi + '/planted/auth/fetch', {
          Id: holdOverId
        }, { headers: { Anonymous: 'true', responseType: 'text' } })
          .toPromise().then(retrievedToken => {
            console.log('Retrieved Token');
            console.log(retrievedToken);
            // @ts-ignore
            localStorage.setItem(environment.key, retrievedToken.value);
            return retrievedToken.value;
          });
      } else {
        this.redirectService.RedirectToLoginPortal();
      }
    } catch (e) {
      console.log({getError: e});
      this.redirectService.RedirectToLoginPortal();
    }
  }

  Set(user: User): void {
    localStorage.setItem(environment.key, JSON.stringify(user));
  }

  Delete(): void {
    localStorage.removeItem(environment.key);
  }

  Check(): boolean {
    return !!localStorage.getItem(environment.key);
  }

  private parseToken(token: string): User {
    let parsedToken = JSON.parse(atob(token.split('.')[1]));
    return new User(
      parsedToken.unique_name,
      parsedToken.exp,
      token
    );
  }
}
