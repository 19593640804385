export class RichDailyModel {
  total: number;
  label: string;
  dayOfWeek: string;

  constructor(props) {
    this.total = props.total;
    this.label = props.label;
    this.dayOfWeek = props.dayOfWeek;
  }
}
