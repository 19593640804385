import {Component, OnInit} from '@angular/core';
import {PlantedChart} from '../../../../planted-charts/planted-chart';
import {ChartConfig} from '../../../../planted-charts/DefinedTypes/chart-config';
import {DateService} from '../../../../services/date.service';
import {ChartType} from 'angular-google-charts';
import {ChartOptions} from '../../../../planted-charts/DefinedTypes/chart-options';
import {ChartService} from '../../../../services/chart.service';
import {SalesDataService} from '../../../../services/sales-data.service';


@Component({
  selector: 'app-glance-four-week',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss']
})
export class FourWeekComponent extends PlantedChart implements OnInit {

  private _title: string;

  // @ts-ignore
  get MaxTitle(): string {
    return this._title;
  }

  // @ts-ignore
  get MinTitle(): string {
    return this._title;
  }

  protected focus: string;

  type = ChartType.ColumnChart;

  constructor(protected dateService: DateService,
              protected chartService: ChartService,
              protected salesDataService: SalesDataService) {
    super(dateService, chartService, salesDataService);
  }

  ngOnInit(): void {
  }

  public Render(): void {
    if (this.salesDataService.Category) {
      this._title = `Weekly Sales for ${this.salesDataService.Category}`;
    } else if (this.salesDataService.Item) {
      this._title = `Weekly Sales for ${this.salesDataService.Item}`;
    } else {
      this._title = 'Weekly Sales';
    }

    this.config = this.ChartSetup();

    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();

    this.chartService.ReDraw(this);
  }

  protected ChartSetup(): ChartConfig {
    let sd = this.salesDataService.Data.fourWeekly.reverse();

    this.FullColumnHeaders = [];
    for (let i = 0; i < sd.length; i++) {
      this.FullColumnHeaders.push(sd[i].start);
    }

    this.ShortColumnHeaders = [];
    for (let i = 0; i < sd.length; i++) {
      this.ShortColumnHeaders.push(sd[i].start);
    }

    let cfg = this.constructConfig();

    cfg.Title = this.MinTitle;
    cfg.Options = new ChartOptions();
    cfg.Options.hAxis.title = 'Week Starting';
    cfg.Options.vAxis.title = 'Sales (USD)';
    cfg.Options.vAxis.format = '$#,###';
    cfg.Options.legend = 'none';
    cfg.Columns = ['Week', 'Sales', {role: 'tooltip'}];

    let toolTipCol: string[] = [];
    for (let c = 0; c < sd.length; c++) {
      toolTipCol.push('Week Starting ' + sd[c].start + ': $' + sd[c].total);
    }

    cfg.Data =
      [
        [sd[0].start, sd[0].total, toolTipCol[0]],
        [sd[1].start, sd[1].total, toolTipCol[1]],
        [sd[2].start, sd[2].total, toolTipCol[2]],
        [sd[3].start, sd[3].total, toolTipCol[3]]
      ];

    return cfg;
  }
}
