import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor() { }

  public RedirectToLoginPortal(): void {
    const term = `${window.location}`;
    window.location.href = `${environment.rootApp}?term=${term}`;
  }
}
