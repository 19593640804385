import {Component, OnInit} from '@angular/core';
import {DateService} from '../../../../services/date.service';
import {PlantedChart} from '../../../../planted-charts/planted-chart';
import {ChartConfig} from '../../../../planted-charts/DefinedTypes/chart-config';
import {ChartType} from 'angular-google-charts';
import {ChartOptions} from '../../../../planted-charts/DefinedTypes/chart-options';
import {ChartService} from '../../../../services/chart.service';
import {SalesDataService} from '../../../../services/sales-data.service';
import {TS} from 'typescript-linq';

@Component({
  selector: 'app-glance-two-month',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss']
})
export class TwoMonthComponent extends PlantedChart implements OnInit {

  _maxTitle: string;
  _minTitle: string;

  // @ts-ignore
  get MaxTitle(): string {
    return this._maxTitle;
  }

  // @ts-ignore
  get MinTitle(): string {
    return this._minTitle;
  }

  protected focus: string;

  type = ChartType.BarChart;

  constructor(protected dateService: DateService,
              protected chartService: ChartService,
              protected salesDataService: SalesDataService) {
    super(dateService, chartService, salesDataService);
  }

  async ngOnInit(): Promise<void> {
  }

  Render(): void {
    if (this.salesDataService.Category) {
      this._maxTitle = `Monthly Sales ${this.salesDataService.Data.twoMonthly[1].month} to ${this.salesDataService.Data.twoMonthly[0].month} for ${this.salesDataService.Category}`;
      this._minTitle = `Monthly Sales For ${this.salesDataService.Category}`;
    } else if (this.salesDataService.Item) {
      this._maxTitle = `Monthly Sales ${this.salesDataService.Data.twoMonthly[1].month} to ${this.salesDataService.Data.twoMonthly[0].month} for ${this.salesDataService.Category}`;
      this._minTitle = `Monthly Sales For ${this.salesDataService.Item}`;
    } else {
      this._maxTitle = `Monthly Sales ${this.salesDataService.Data.twoMonthly[1].month} to ${this.salesDataService.Data.twoMonthly[0].month}`;
      this._minTitle = `Monthly Sales`;
    }

    this.config = this.ChartSetup();
    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();

    this.chartService.ReDraw(this);
  }



  public ChartSetup(): ChartConfig {
    let cfg = this.constructConfig();
    let sd = new TS.Linq.Enumerator(this.salesDataService.Data.twoMonthly).reverse().toArray();
    cfg.Title = this.MinTitle;
    cfg.Options = new ChartOptions();
    cfg.Options.hAxis.title = 'Sales (USD)';
    cfg.Options.hAxis.format = '$#,###';
    cfg.Options.vAxis.title = 'Month';
    cfg.Columns = ['Month', 'Sales', {role: 'tooltip'}];
    cfg.Options.legend = 'none';

    let startDisplay = sd[0].month;
    let endDisplay = sd[1].month;
    this.FullColumnHeaders = [startDisplay, endDisplay];

    let startMY = `${sd[0].month}: $${sd[0].total}`;
    let endMY = `${sd[1].month}: $${sd[1].total}`;
    let toolTipCol: string[] = [startMY, endMY];

    cfg.Data = [];

    for (let i = 0; i < sd.length; i++) {
      cfg.Data.push([sd[i].month, sd[i].total, toolTipCol[i]]);
    }

    return cfg;
  }
}
