import {Injectable} from '@angular/core';
import {PlantedChart} from '../planted-charts/planted-chart';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  screenHeight = window.innerHeight;
  screenWidth = window.innerWidth;

  ChartMinH: number;
  ChartMaxH: number;
  ChartMinW: number;
  ChartMaxW: number;

  ChartIndex: number;

  Charts: PlantedChart[];

  constructor() {
    this.Charts = [];
  }

  ReDraw(chart?: PlantedChart): void {
    if (chart) {
      if (chart.isInFocus) {
        chart.ReDraw(this.ChartMaxH, this.ChartMaxW);
      } else {
        chart.ReDraw(this.ChartMinH, this.ChartMinW);
      }
    } else {
      this.Charts.forEach(x => {
        if (x.isInFocus) {
          x.ReDraw(this.ChartMaxH, this.ChartMaxW);
        } else {
          x.ReDraw(this.ChartMinH, this.ChartMinW);
        }
      });
    }
  }
}
