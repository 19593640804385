export class DailyModel {
  total: number;
  label: string;

  constructor(props) {
    this.total = props.total;
    this.label = props.label;
  }

}
