import {Component, OnInit} from '@angular/core';
import {TimeSpan} from '../../../../time-span.enum';
import {DateService} from '../../../../services/date.service';
import {PlantedChart} from '../../../../planted-charts/planted-chart';
import {ModelFilter} from '../../../../LibraryTypes/model-filter';
import {ChartConfig} from '../../../../planted-charts/DefinedTypes/chart-config';
import {ChartType} from 'angular-google-charts';
import {ChartOptions} from '../../../../planted-charts/DefinedTypes/chart-options';
import {ChartService} from '../../../../services/chart.service';
import {SalesDataService} from '../../../../services/sales-data.service';
import {TS} from 'typescript-linq';
import Linq = TS.Linq;
import {DailyModel} from "../../../../models/daily-model";

@Component({
  selector: 'app-item-quantity',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss']
})
export class ItemQuantityComponent extends PlantedChart implements OnInit {


  private _title: string;

  // @ts-ignore
  get MaxTitle(): string {
    return this._title;
  }

  // @ts-ignore
  get MinTitle(): string {
    return this._title;
  }


  type = ChartType.ColumnChart;

  constructor(protected dateService: DateService,
              protected chartService: ChartService,
              protected salesDataService: SalesDataService) {
    super(dateService, chartService,  salesDataService);
  }

  async ngOnInit(): Promise<void> {
  }

  public Render(): void {

    if (this.salesDataService.Category) {

      this._title = `Top Item Quantities for ${this.salesDataService.Category} - (Rolling 30 Days)`;
    } else {
      this._title = 'Top Item Quantities - (Rolling 30 Days)';
    }

    this.config = this.ChartSetup();

    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();

    this.chartService.ReDraw(this);
  }

  ChartSetup(): ChartConfig {
    let cfg = this.constructConfig();
    let sd = this.salesDataService.Data.thirtyDaysRollingItemSalesQuantity;
    cfg.Title = this.MinTitle;
    cfg.Options = new ChartOptions();
    cfg.Options.legend = 'none';
    cfg.Options.hAxis.title = 'Item';
    cfg.Options.vAxis.title = 'Quantity';
    cfg.Options.vAxis.format = '#,###';
    cfg.Columns = ['Item', 'Sales', {role: 'tooltip'}];

    this.FullColumnHeaders = new Linq.Enumerator(sd).select(x => {
      let label = x.label;
      if (this.salesDataService.Category)
        label = label.replace(`${this.salesDataService.Category}-`, '');
      return label;
    }).toArray();
    this.ShortColumnHeaders = this.FullColumnHeaders;

    // Limit result set to 6 columns.
    sd = new TS.Linq.Enumerator(sd).take(6).defaultIfEmpty(new DailyModel({label: '-', total: '0'})).toArray();

    cfg.Data = [];

    // [sd[0].label, sd[0].total, `${sd[0].label}: $${sd[0].total}`],
    //   [sd[1].label, sd[1].total, `${sd[1].label}: $${sd[1].total}`],
    //   [sd[2].label, sd[2].total, `${sd[2].label}: $${sd[2].total}`],
    //   [sd[3].label, sd[3].total, `${sd[3].label}: $${sd[3].total}`],
    //   [sd[4].label, sd[4].total, `${sd[4].label}: $${sd[4].total}`],
    //   [sd[5].label, sd[5].total, `${sd[5].label}: $${sd[5].total}`]

    sd.forEach(x => {
      cfg.Data.push([x.label, x.total, `${x.label}: ${x.total}`]);
    });

    return cfg;
  }
}
