import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {SalesDashboardModel} from '../models/sales-dashboard-model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SalesDataService implements Resolve<SalesDashboardModel> {

  ApiUrl = environment.demeterApi + '/planted/CrashPAD/sales';

  Data: SalesDashboardModel;

  Category: string;
  Item: string;
  constructor(private http: HttpClient) {
  }

  // tslint:disable-next-line:max-line-length
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): SalesDashboardModel | import('rxjs').Observable<SalesDashboardModel> | Promise<SalesDashboardModel> {
    console.log(route);
    return this.Load(route);
  }

  async Load(route: ActivatedRouteSnapshot = null): Promise<SalesDashboardModel> {
    console.log('Loading Sales Data');
    const params = new URLSearchParams(window.location.search);

    let product = await this.Reload(params.get('category'), params.get('item'));
    return product;
  }

  async Reload(category: string, item: string): Promise<SalesDashboardModel> {
    if (!this.Category) this.Category = '';
    if (!this.Item) this.Item = '';

    // If we have the same arguments and the data is already populated then skip this call returning what we have.
    if (this.Category === category && this.Item === item && this.Data) return this.Data;

    this.Category = category;
    this.Item = item;


    let outgoingParams = new HttpParams().set('category', this.Category).set('item', this.Item);
    this.Data = await this.http.get<SalesDashboardModel>(this.ApiUrl, { params: outgoingParams }).toPromise();
    return this.Data;
  }
}
