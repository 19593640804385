import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {GoogleChartsModule} from 'angular-google-charts';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatChipsModule} from '@angular/material/chips';

import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

// import {DashboardComponent} from './components/dashboard/dashboard.component';
// import {CrashpadComponent} from './components/dashboard/crashpad/crashpad.component';
import {YtdComponent} from './components/dashboard/sales/charts/ytd.component';
import {SalesComponent} from './components/dashboard/sales/sales.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {TwoMonthComponent} from './components/dashboard/sales/charts/two-month.component';
import {FourWeekComponent} from './components/dashboard/sales/charts/four-week.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FourteenDayComponent} from './components/dashboard/sales/charts/fourteen-day.component';
import {MatButtonModule} from '@angular/material/button';
import {
  ButtonModule,
  CalendarModule,
  DropdownModule,
  MessageService,
  TableModule,
  TabMenuModule,
  TabViewModule, ToastModule
} from 'primeng';
import {MatSliderModule} from '@angular/material/slider';
import {TopCategoryComponent} from './components/dashboard/sales/charts/top-category.component';
// import {TopItemComponent} from './components/dashboard/sales/charts/top-item.component';
import {ZipCodeComponent} from './components/dashboard/sales/charts/zip-code.component';
import {ItemQuantityComponent} from './components/dashboard/sales/charts/item-quantity.component';
// import { SalesPowerUserTableComponent } from './components/dashboard/sales/sales-power-user-table/sales-power-user-table.component';
import {JwtInterceptor} from './interceptors/jwt-interceptor';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LandingComponent } from './components/landing/landing.component';
import {SalesDataService} from './services/sales-data.service';
import {TopItemComponent} from "./components/dashboard/sales/charts/top-item.component";
// import { GrowersComponent } from './components/dashboard/growers/growers.component';
// import { ChartPlaceholderComponent } from './components/dashboard/growers/chart-placeholder/chart-placeholder.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    // CrashpadComponent,
    // DashboardComponent,
    YtdComponent,
    SalesComponent,
    TwoMonthComponent,
    FourWeekComponent,
    FourteenDayComponent,
    TopCategoryComponent,
    TopItemComponent,
    ItemQuantityComponent,
    ZipCodeComponent,
    // SalesPowerUserTableComponent,
    NavbarComponent,
    // LandingComponent,
    // GrowersComponent,
    // ChartPlaceholderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    GoogleChartsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    ButtonModule,
    TabViewModule,
    DropdownModule,
    MatSliderModule,
    CalendarModule,
    TableModule,
    TabMenuModule,
    ToastModule
  ],
  providers: [
    MessageService,
    SalesDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
