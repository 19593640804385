import {Component, HostListener, OnInit} from '@angular/core';
import {LoginService} from './services/login.service';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ChartService} from './services/chart.service';
import {TS} from 'typescript-linq';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'Client';
  deskMinWidth = .2;
  deskMaxWidth = .8;
  deskMinHeight = .2;
  deskMaxHeight = .75;

  mobileMinWidth = .85;
  mobileMaxWidth = .5;
  mobileMinHeight = .45;
  mobileMaxHeight = .5;

  minWidth = 350;
  minHeight = 275;

  public loggedIn = false;


  constructor(
    public loginService: LoginService,
    private chartService: ChartService
  ) {
  }

  resize$ = new Subject<void>();

  ngOnInit(): void {
    this.resize$.pipe(debounceTime(300)).subscribe(() => {
      this.chartService.screenHeight = window.innerHeight * 1.15;
      this.chartService.screenWidth = window.innerWidth * 1.2;

      let minW: number;
      let maxW: number;
      let minH: number;
      let maxH: number;


      if (this.chartService.screenWidth > 750) {
        minW = this.chartService.screenWidth * this.deskMinWidth;
        maxW = this.chartService.screenWidth * this.deskMaxWidth;
        minH = this.chartService.screenHeight * this.deskMinHeight;
        maxH = this.chartService.screenHeight * this.deskMaxHeight;
      } else {
        minW = this.chartService.screenWidth * this.mobileMinWidth;
        maxW = this.chartService.screenHeight * this.mobileMaxWidth;
        minH = this.chartService.screenWidth * this.mobileMinHeight;
        maxH = this.chartService.screenHeight * this.mobileMaxHeight;
      }
      this.chartService.ChartMinW = minW > this.minWidth ? minW : this.minWidth;
      this.chartService.ChartMaxW = maxW > this.minWidth ? maxW : this.minWidth;
      this.chartService.ChartMinH = minH > this.minHeight ?  minH : this.minHeight;
      this.chartService.ChartMaxH = maxH > this.minHeight ?  maxH : this.minHeight;

      console.log('MinW' + this.chartService.ChartMinW);
      console.log('MaxW' + this.chartService.ChartMaxW);
      console.log('MinH' + this.chartService.ChartMinH);
      console.log('MaxH' + this.chartService.ChartMaxH);

      this.chartService.ReDraw();
    });
  }

  public ShowLogo(): boolean {
    return this.GetBrowser() === 'Edge (Chromium)' && !this.IsChartInFocus();
  }

  public GetBrowser(): string {
    let label;
    if (navigator.userAgent.includes('Firefox/')) {
      label = 'Firefox';
    } else if (navigator.userAgent.includes('Edg/')) {
      label = 'Edge (Chromium)';
    } else if (navigator.userAgent.includes('Chrome/')) {
      label = 'Chrome';
    } else if (navigator.userAgent.includes('Safari/')) {
      label = 'Safari';
    }
    return label;
  }

  public IsChartInFocus(): boolean {
    return new TS.Linq.Enumerator(this.chartService.Charts).any(x => x.isInFocus);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.resize$.next();
  }
}
