import { Component, OnInit } from '@angular/core';
import {MenuItem} from 'primeng';
import {Router} from '@angular/router';
import {LoginService} from '../../services/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  items: MenuItem[];

  constructor(private router: Router, private loginService: LoginService) {}


  ngOnInit(): void {
    this.items = [
      {label: 'Sales', icon: 'pi pi-fw pi-dollar', command: event => this.route('sales')},
      {label: 'Grower', icon: 'pi pi-fw pi-th-large', command: event => this.route('growers')},
      {label: 'Packaging', icon: 'pi pi-fw pi-ticket'},
      {label: 'Logout', icon: 'pi pi-fw pi-sign-out', command: event => this.loginService.LogOut()}
    ];
  }

  private route(arg: string): void {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([arg]).then();
    });
  }
}
