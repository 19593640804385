import {ChartType, Column, Row} from 'angular-google-charts';
import {ChartOptions} from './chart-options';
import {GoogleChartsConfig} from 'angular-google-charts/lib/models/google-charts-config.model';

export class ChartConfig implements GoogleChartsConfig {
  public Title: string;
  public Type: ChartType;
  public Data: Row[];
  public Columns: Column[];
  public Options: ChartOptions;
  public Width: number;
  public Height: number;
  public DynamicResize: boolean;
  public Visible: boolean;
  public mapsApiKey?: string;
  public version?: string;
  public safeMode?: boolean;
  public formatters: any;

  public ProtoType(): ChartConfig {
    let product = new ChartConfig();
    product.Title = this.Title;
    product.Type = this.Type;
    product.Data = this.Data;
    product.Columns = this.Columns;
    product.Width = this.Width;
    product.Height = this.Height;
    product.DynamicResize = this.DynamicResize;
    product.Visible = this.Visible;
    product.version = this.version;
    product.safeMode = this.safeMode;
    product.formatters = this.formatters;
    return product;
  }
}
