import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {SalesDataService} from '../../services/sales-data.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(
    private router: Router,
    private tokenService: AuthService,
    public salesDataService: SalesDataService
  ) {
  }

  async ngOnInit(): Promise<void> {
    // console.log('Loading');
    // await this.tokenService.Get(); // Caching access token in advance to prevent unnecessary triple attempts at
    // // garnering a token.
    // await this.router.navigate(['sales']);
    console.group('Login');
    const token = this.tokenService.Get();
    console.log(token);
    console.groupEnd();

    console.group('SalesData');
    const salesData = await this.salesDataService.Load();
    console.log(salesData);
    console.groupEnd();

    console.group('Login and data pull complete. Moving to Sales Dash');
    await this.router.navigate(['sales']);
    console.groupEnd();
  }
}
