import {Component, OnInit} from '@angular/core';
import {DateService} from '../../../../services/date.service';
import {PlantedChart} from '../../../../planted-charts/planted-chart';
import {ChartConfig} from '../../../../planted-charts/DefinedTypes/chart-config';
import {ChartType} from 'angular-google-charts';
import {ChartOptions} from '../../../../planted-charts/DefinedTypes/chart-options';
import {ChartService} from '../../../../services/chart.service';
import {SalesDataService} from '../../../../services/sales-data.service';
import {TS} from 'typescript-linq';
import Enumerator = TS.Linq.Enumerator;

@Component({
  selector: 'app-top-category',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss']
})
export class TopCategoryComponent extends PlantedChart implements OnInit {

  private _title: string;

  // @ts-ignore
  get MaxTitle(): string {
    return this._title;
  }

  // @ts-ignore
  get MinTitle(): string {
    return this._title;
  }

  columnValues: number[];

  type = ChartType.ColumnChart;

  constructor(protected dateService: DateService,
              protected chartService: ChartService,
              protected salesDataService: SalesDataService) {
    super(dateService, chartService, salesDataService);
  }

  async ngOnInit(): Promise<void> {
  }

  public Render(): void {
    this._title = 'Top Sales By Category (Rolling 30 Days)';

    if (this.config) {
      // Do Nothing
    } else {
      this.config = this.ChartSetup();
    }

    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();

    this.chartService.ReDraw(this);
  }

  ChartSetup(): ChartConfig {
    let cfg = this.constructConfig();
    let sd = this.salesDataService.Data.thirtyDaysRollingCategorySales;

    this.ShortColumnHeaders = new Enumerator(sd).select(x => x.label).toArray();
    this.FullColumnHeaders = this.ShortColumnHeaders;

    cfg.Title = this.MinTitle;
    cfg.Options = new ChartOptions();
    cfg.Options.legend = 'none';
    cfg.Options.hAxis.title = 'Category';
    cfg.Options.vAxis.title = 'Sales (USD)';
    cfg.Options.vAxis.format = '$#,###';
    cfg.Columns = ['Category', 'Sales', {role: 'tooltip'}];


    let fullColumnHeaders = [];
    let truncColumnHeaders = [];
    let columnValues = [];

    for (let i = 0; i < sd.length; i++) {
      let entry = sd[i];
      fullColumnHeaders.push(entry.label);
      truncColumnHeaders.push(entry.label);
      columnValues.push(entry.total);
    }

    this.FullColumnHeaders = fullColumnHeaders;
    this.ShortColumnHeaders = truncColumnHeaders;
    this.columnValues = columnValues;

    cfg = this.Reload(cfg, this.ShortColumnHeaders);

    return cfg;
  }

  public Reload(cfg: ChartConfig, headers: string[]): ChartConfig {
    let cHL: string[] = this.FullColumnHeaders;
    let toolTipCol: string[] = [];
    for (let c = 0; c < cHL.length; c++) {
      toolTipCol.push(cHL[c] + ': $' + this.columnValues[c]);
    }

    let sd = this.salesDataService.Data.thirtyDaysRollingCategorySales;

    cfg.Data =
      [
        [sd[0].label, sd[0].total, toolTipCol[0]],
        [sd[1].label, sd[1].total, toolTipCol[1]],
        [sd[2].label, sd[2].total, toolTipCol[2]],
        [sd[3].label, sd[3].total, toolTipCol[3]],
        [sd[4].label, sd[4].total, toolTipCol[4]],
        [sd[5].label, sd[5].total, toolTipCol[5]]
      ];

    return cfg;
  }
}

