export class TextStyle {
  color: string;
  fontName: string;
  fontSize: number;
  bold: boolean;
  italic: boolean;

  constructor(
    color: string = '#fff',
    fontName: string = null,
    fontSize: number = null,
    bold: boolean = false,
    italic: boolean = false
  ) {
    this.color = color;
    this.fontName = fontName;
    this.fontSize = fontSize;
    this.bold = bold;
    this.italic = italic;
  }
}
