import {SelectItem, SelectItemGroup} from 'primeng';
import {SalesDataService} from '../services/sales-data.service';
import {PDropdownItem} from './pdropdown-item';
import {TS} from 'typescript-linq';
import Linq = TS.Linq;

export class PDropdownCategory implements SelectItemGroup {
  label: string;
  value: any;
  items: SelectItem[];

  constructor(category: string, variants: string[]) {
    this.label = category;
    this.value = category;

    this.items = new Linq.Enumerator(variants).where(v => v.includes(category))
      .select(va => new PDropdownItem(va, va)).toArray();
  }
}
