import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {flatMap, tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {RedirectService} from '../services/redirect.service';
import {environment} from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private tokenService: AuthService, private redirectService: RedirectService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authRequest(req, next);
  }

  private authRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('BASE REQUEST');
    console.log({req});
    if (req.headers.get('Anonymous')) {
      console.log('Request is Anonymous. Skipping JWT.');
      return next.handle(req);
    } else {
      console.log('Request Requires Authorization.');
      // We retrieve the token, if any
      // @ts-ignore
      return from(this.tokenService.Get().then(token => {
        let newHeaders = req.headers;
        // If we have a token, we append  it to our new headers
        console.log({token});
        newHeaders = newHeaders
          .append('Authorization', 'bearer ' + token);
        // Finally we have to clone our request with our new headers
        // This is required because HttpRequests are immutable
        return req.clone({headers: newHeaders});
        // Then we return an Observable that will run the request
        // or pass it to the next interceptor if any
      })).pipe(
        flatMap((authReq) => {
            console.log('New Authorized Request');
            console.log(authReq);
            // execute second Call passing results from first call
            return next.handle(authReq).pipe(tap(() => {
              },
              async (err: any) => {
                if (err instanceof HttpErrorResponse) {
                  if (err.status !== 401) {
                    return;
                  }
                  console.log({err});
                  localStorage.removeItem(environment.key);
                  this.redirectService.RedirectToLoginPortal();
                }
              }));
          }
        )
      );
    }
  }
}
