import {Component, OnInit} from '@angular/core';
import {PlantedChart} from '../../../../planted-charts/planted-chart';
import {TimeSpan} from '../../../../time-span.enum';
import {DateService} from '../../../../services/date.service';
import {ChartConfig} from '../../../../planted-charts/DefinedTypes/chart-config';
import {endOfDay, lightFormat, startOfDay} from 'date-fns';
import {ChartOptions} from '../../../../planted-charts/DefinedTypes/chart-options';
import {ChartType} from 'angular-google-charts';
import {ChartService} from '../../../../services/chart.service';
import {SalesDataService} from '../../../../services/sales-data.service';
import {RichDailyModel} from '../../../../models/rich-daily-model';
import {TS} from 'typescript-linq';
import Enumerator = TS.Linq.Enumerator;

@Component({
  selector: 'app-fourteen-day',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss']
})
export class FourteenDayComponent extends PlantedChart implements OnInit  {

  _title: string;

  // @ts-ignore
  get MaxTitle(): string {
    return this._title;
  }

  // @ts-ignore
  get MinTitle(): string {
    return this._title;
  }


  protected focus: string;

  type = ChartType.ColumnChart;

  constructor(protected dateService: DateService,
              protected chartService: ChartService,
              protected salesDataService: SalesDataService) {
    super(dateService, chartService, salesDataService);
  }

  ngOnInit(): void {}

  public Render(): void {

    let appendedValue = this.salesDataService.Category ?? this.salesDataService.Item ?? null;

    let numerator = new TS.Linq.Enumerator(this.salesDataService.Data.fourteenDaily).orderBy(x => x.label);
    let first = numerator.firstOrDefault(new RichDailyModel({label: '-'})).label;
    let last = numerator.lastOrDefault(new RichDailyModel({label: '-'})).label;
    this._title = `14 Day Sales`;

    if (appendedValue) {
      this._title += ` - ${appendedValue}`;
    }

    this._title += `- (${first} to ${last})`;

    this.config = this.ChartSetup();
    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();
  }

  public ChartSetup(): ChartConfig {
    let cfg = this.constructConfig();

    cfg.Title = this.MinTitle;
    cfg.Options = new ChartOptions();
    cfg.Options.vAxis.title = 'Sales (USD)';
    cfg.Options.vAxis.format = '$#,###';
    cfg.Options.colors = ['#a02388', '#63c6c1'];

    cfg.Columns = ['Day', 'Last', {role: 'tooltip'}, 'Current', {role: 'tooltip'}];

    let sd = this.salesDataService.Data.fourteenDaily;
    let numerator = new Enumerator(sd);

    let mon = numerator.where(x => x.dayOfWeek === 'MON').orderByDescending(x => x.label).toArray();
    let tue = numerator.where(x => x.dayOfWeek === 'TUE').orderByDescending(x => x.label).toArray();
    let wed = numerator.where(x => x.dayOfWeek === 'WED').orderByDescending(x => x.label).toArray();
    let thu = numerator.where(x => x.dayOfWeek === 'THU').orderByDescending(x => x.label).toArray();
    let fri = numerator.where(x => x.dayOfWeek === 'FRI').orderByDescending(x => x.label).toArray();
    let sat = numerator.where(x => x.dayOfWeek === 'SAT').orderByDescending(x => x.label).toArray();
    let sun = numerator.where(x => x.dayOfWeek === 'SUN').orderByDescending(x => x.label).toArray();

    this.FullColumnHeaders = [
      this.GetFullHeader(mon),
      this.GetFullHeader(tue),
      this.GetFullHeader(wed),
      this.GetFullHeader(thu),
      this.GetFullHeader(fri),
      this.GetFullHeader(sat),
      this.GetFullHeader(sun),
    ];

    this.ShortColumnHeaders = [
      'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN '
    ];

    // TODO INTEGRATE LABELS
    cfg.Data =
      [
        [mon[0].dayOfWeek, mon[1]?.total ?? 0, this.GetTooltip(mon[1]), mon[0]?.total ?? 0, this.GetTooltip(mon[0])],
        [tue[0].dayOfWeek, tue[1]?.total ?? 0, this.GetTooltip(tue[1]), tue[0]?.total ?? 0, this.GetTooltip(tue[0])],
        [wed[0].dayOfWeek, wed[1]?.total ?? 0, this.GetTooltip(wed[1]), wed[0]?.total ?? 0, this.GetTooltip(wed[0])],
        [thu[0].dayOfWeek, thu[1]?.total ?? 0, this.GetTooltip(thu[1]), thu[0]?.total ?? 0, this.GetTooltip(thu[0])],
        [fri[0].dayOfWeek, fri[1]?.total ?? 0, this.GetTooltip(fri[1]), fri[0]?.total ?? 0, this.GetTooltip(fri[0])],
        [sat[0].dayOfWeek, sat[1]?.total ?? 0, this.GetTooltip(sat[1]), sat[0]?.total ?? 0, this.GetTooltip(sat[0])],
        [sun[0].dayOfWeek, sun[1]?.total ?? 0, this.GetTooltip(sun[1]), sun[0]?.total ?? 0, this.GetTooltip(sun[0])],
      ];

    return cfg;
  }

  private GetFullHeader(dayGroup: RichDailyModel[]): string {
    return `${dayGroup[1].label}/${dayGroup[0].label}`;
  }

  private GetTooltip(day: RichDailyModel): string {
    if (day) return `${day.label}: $${day?.total ?? 0} - ${day.dayOfWeek}`;
    else return 'NO DATA';
  }
}
