import {ChartDecorator} from './chart-decorator';
import { NONE_TYPE } from '@angular/compiler';

export class Axis {
  title = '';
  titleTextStyle = new ChartDecorator();
  textStyle: ChartDecorator;
  gridlines: ChartDecorator;
  minValue: number;
  maxValue: number;
  format: string;

  public constructor(
    title: string = '',
    titleTextStyle: ChartDecorator = new ChartDecorator(),
    textStyle: ChartDecorator = new ChartDecorator('D3D3D3', false),
    gridlines: ChartDecorator = new ChartDecorator('#3e3a3b', false),
    minValue: number = 0,
    maxValue: number = 0,
    format: string = 'none') {

    this.title = title;
    this.titleTextStyle = titleTextStyle;
    this.textStyle = textStyle;
    this.gridlines = gridlines;
    this.minValue = 0;
    this.maxValue = 0;
    this.format = '';
  }
}
