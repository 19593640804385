import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {MessageService} from 'primeng';
import {environment} from '../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from './auth.service';
import {RedirectService} from './redirect.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  apiUrl = environment.demeterApi + '/auth';

  constructor(private http: HttpClient,
              private router: Router,
              private messageService: MessageService,
              private tokenService: AuthService,
              private redirectService: RedirectService) {
  }

  // async Register(username: string, password: string): Promise<void> {
  //   this.http.post(this.apiUrl + '/register', {username, password}).subscribe(resp => {
  //     this.messageService.add({severity: 'success', summary: 'Service Message', detail: 'Via MessageService'});
  //     return;
  //   });
  // }

  authFailed(): void {
    this.redirectService.RedirectToLoginPortal();
  }

  LogOut(): void {
    this.tokenService.Delete();
    this.redirectService.RedirectToLoginPortal();
  }
}
