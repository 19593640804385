import {ChartDecorator} from './chart-decorator';
import {Axis} from './axis';
import {ChartConfig} from './chart-config';
import {Legend} from './legend';
import {Tooltip} from './tooltip';
import {TextStyle} from './text-style';

export class ChartOptions {
  is3d: boolean;
  isStacked: boolean;
  backgroundColor: string;
  colors: string[];
  chartArea: ChartDecorator;
  titleTextStyle: ChartDecorator;
  legend: (Legend | TextStyle | string);
  tooltip: Tooltip;
  hAxis: Axis;
  vAxis: Axis;
  seriesType: string;
  series: any;

  public Prototype(): ChartOptions {
    let product = new ChartOptions();
    product.is3d = this.is3d;
    product.isStacked = this.isStacked;
    product.backgroundColor = this.backgroundColor;
    product.colors = this.colors;
    product.chartArea = this.chartArea;
    product.legend = this.legend;
    product.tooltip = this.tooltip;
    product.hAxis = this.hAxis;
    product.vAxis = this.vAxis;
    return product;
  }

  public constructor(
    is3d = true,
    isStacked = false,
    backgroundColor = '#0e2029',
    colors = ['#63c6c1', '#a02388', '#fff'],
    chartArea = new ChartDecorator(),
    titleTextStyle = new ChartDecorator('#ffffff'),
    legend: Legend = new Legend(true),
    hAxis = new Axis(),
    yAxis = new Axis()
  ) {
    this.is3d = is3d;
    this.isStacked = isStacked;
    this.backgroundColor = backgroundColor;
    this.colors = colors;
    chartArea.backgroundColor = '#0e2029';
    this.chartArea = chartArea;
    this.titleTextStyle = titleTextStyle;
    this.legend = legend;
    this.hAxis = hAxis;
    this.vAxis = yAxis;
    this.seriesType = null;
    this.series = null;
  }
}
