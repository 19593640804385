import {Component, OnInit} from '@angular/core';
import {DateService} from '../../../../services/date.service';
import {PlantedChart} from '../../../../planted-charts/planted-chart';
import {ChartConfig} from '../../../../planted-charts/DefinedTypes/chart-config';
import {ChartType} from 'angular-google-charts';
import {ChartOptions} from '../../../../planted-charts/DefinedTypes/chart-options';
import {ChartService} from '../../../../services/chart.service';
import {SalesDataService} from '../../../../services/sales-data.service';
import {TS} from 'typescript-linq';
import Linq = TS.Linq;

@Component({
  selector: 'app-top-item',
  templateUrl: './chart.html',
  styleUrls: ['./chart.scss']
})
export class TopItemComponent extends PlantedChart implements OnInit {

  _title: string;

  // @ts-ignore
  get MaxTitle(): string {
    return this._title;
  }

  // @ts-ignore
  get MinTitle(): string {
    return this._title;
  }

  type = ChartType.ColumnChart;

  constructor(protected dateService: DateService,
              protected chartService: ChartService,
              protected salesDataService: SalesDataService) {
    super(dateService, chartService,  salesDataService);
  }

  async ngOnInit(): Promise<void> {
  }

  public Render(): void {
    if (this.salesDataService.Category) {
      this._title = `Top Item Sales for ${this.salesDataService.Category} - (Rolling 30 Days)`;
    } else {
      this._title = 'Top Item Sales - (Rolling 30 Days)';
    }

    this.config = this.ChartSetup();

    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();

    this.chartService.ReDraw(this);
  }

  ChartSetup(): ChartConfig {
    let cfg = this.constructConfig();
    let sd = new TS.Linq.Enumerator(this.salesDataService.Data.thirtyDaysRollingItemSalesTotals).take(6).toArray();
    cfg.Title = this.MinTitle;
    cfg.Options = new ChartOptions();
    cfg.Options.legend = 'none';
    cfg.Options.hAxis.title = 'Item';
    cfg.Options.vAxis.title = 'Sales (USD)';
    cfg.Options.vAxis.format = '$#,###';
    cfg.Columns = ['Item', 'Sales', {role: 'tooltip'}];

    this.FullColumnHeaders = new Linq.Enumerator(sd).select(x => {
      let label = x.label;
      if (this.salesDataService.Category)
        label = label.replace(`${this.salesDataService.Category}-`, '');
      return label;
    }).toArray();
    this.ShortColumnHeaders = this.FullColumnHeaders;

    cfg.Data = [];

    for (let i = 0; i < sd.length; i++) {
      cfg.Data.push([sd[i].label, sd[i].total, `${sd[i].label}: $${sd[i].total}`]);
    }
    return cfg;
  }
}

