import {DateService} from '../services/date.service';
import {ChartConfig} from './DefinedTypes/chart-config';
import {TS} from 'typescript-linq';
import Enumerator = TS.Linq.Enumerator;
import {ChartType} from 'angular-google-charts';
import {ChartService} from '../services/chart.service';
import {SalesDataService} from '../services/sales-data.service';
export abstract class PlantedChart {

  // @ts-ignore
  get FullColumnHeaders(): string[] {
    if (!this._FullColumnHeaders) return this._ShortColumnHeaders;
    else return this._FullColumnHeaders;
  }

  // @ts-ignore
  set FullColumnHeaders(value: string[]) {
    this._FullColumnHeaders = value;
  }

  // @ts-ignore
  get ShortColumnHeaders(): string[] {
    if (!this._ShortColumnHeaders) return this._FullColumnHeaders;
    else return this._ShortColumnHeaders;
  }

  // @ts-ignore
  set ShortColumnHeaders(value: string[]) {
    this._ShortColumnHeaders = value;
  }

  public config: ChartConfig;

  public titleSuffix = '';

  public abstract MaxTitle: string;
  public abstract MinTitle: string;
  protected initialized = false;

  private _ShortColumnHeaders: string[];
  private _FullColumnHeaders: string[];

  public isInFocus = false;

  public abstract type: ChartType;

  protected constructor(
    protected dateService: DateService,
    protected chartService: ChartService,
    protected salesDataService: SalesDataService
   ) {
  }

  protected abstract ChartSetup(): ChartConfig;

  public Render(): void {

    this.config = this.ChartSetup();

    if (this.isInFocus) this.DisplayFullColumnHeaders();
    else this.DisplayShortColumnHeaders();
  }

  public DisplayFullColumnHeaders(): ChartConfig {
    try {
      return this.UpdateColumnHeaders(this.FullColumnHeaders);
    } catch (e) {
      console.log(e);
    }
  }

  public DisplayShortColumnHeaders(): ChartConfig {
    try {
      return this.UpdateColumnHeaders(this.ShortColumnHeaders);
    } catch (e) {
      console.log(e);
    }
  }

  private UpdateColumnHeaders(headers: string[]): ChartConfig {
    let data = new Enumerator(this.config.Data).toArray();
    for (let i = 0; i < data.length; i++) {
      let dataRow = this.config.Data[i];
      dataRow[0] = headers[i];
    }

    this.config.Data = data;

    return this.config;
  }

  protected constructConfig(): ChartConfig {
    let cfg = new ChartConfig();
    cfg.Type = this.type;
    if (this.config) {
      cfg.Height = this.config.Height;
      cfg.Width = this.config.Width;
    } else {
      cfg.Height = this.chartService.ChartMinH;
      cfg.Width = this.chartService.ChartMinW;
    }

    return cfg;
  }

  ReDraw(height: number, width: number): void {
    this.config.Height = height;
    this.config.Width = width;
  }
}
