import {TextStyle} from './text-style';

export class ChartDecorator {
  color: string;
  fontName: string;
  fontSize: number;
  backgroundColor: string;
  textStyle: TextStyle;
  bold: boolean;
  italic: boolean;

  public constructor(
    color: string = '#fff',
    bold: boolean = true,
    italic: boolean = false,
    backgroundColor = null) {
    this.color = color;
    this.backgroundColor = backgroundColor;
    this.textStyle = new TextStyle();
    this.bold = bold;
    this.italic = italic;
  }
}
